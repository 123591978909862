import gsap from 'gsap';

const t1 = gsap.timeline();

export const pageTransition = (primaryFrame, backgroundFrame) => {
  t1.fromTo(
    backgroundFrame,
    { scaleX: 0 },
    { duration: 0.8, scaleX: 1, transformOrigin: 'left', ease: 'power4.inOut' },
    0.2
  )
    .set(primaryFrame, { scaleX: 0 })
    .to(backgroundFrame, { duration: 0.8, scaleX: 0, transformOrigin: 'right', ease: 'power4.inOut' });
};

export const projectTransition = (containerRef) => {
  const q = gsap.utils.selector(containerRef);
  t1.from(q('article'), { duration: 0.8, x: -40, opacity: 0, ease: 'power3.inOut', stagger: 0.2 });
};
